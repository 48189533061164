/**
 * Javascript for translations
 */
import $ from "jquery";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector"; 
    
var callback;

export var initTranslations = async function(callback2) {
	callback = callback2;
    await initTranslations2();
    /*
    if (i18next.resolvedLanguage!='en') {
		//loadNewsArticles(i18next.resolvedLanguage);
		callback(i18next.resolvedLanguage);
	}*/
    translatePageElements();
    
	bindLocaleSwitcher(i18next.resolvedLanguage);
};

/**
 * Translations.
 * We make the function async so we can await
 * the translation file as it pipes down the
 * network
 */
async function initTranslations2() {
  // We use() the backend and await it to load
  // the translations from the network
  await i18next
  	.use(HttpApi)
	.use(LanguageDetector)
	.init({
	    //debug: true,
    	supportedLngs: ["en", "fr", "de", "no", "es", "it", "pt"],
	    fallbackLng: "en",
	    
        // Allow "en" to be used for
        // "en-US", "en-CA", etc.
        nonExplicitSupportedLngs: true,
  		load: 'languageOnly',
      
	    // Configure Http backend
	    backend: {
	        loadPath: "/assets/lang/{{lng}}.json",
	    },
    });
}

/**
 * Apply translations
 */
function translatePageElements() {
  const translatableElements = document.querySelectorAll(
    "[data-i18n-key]",
  );
  translatableElements.forEach((el) => {
    const key = el.getAttribute("data-i18n-key");
    //console.log('applying translation for', key)
    el.innerHTML = i18next.t(key);
  });
  
  //translate attributes
  const translatableAttributes = document.querySelectorAll(
    "[data-i18n-attribute]",
  );
  translatableAttributes.forEach((el) => {
    const keys = el.getAttribute("data-i18n-attribute").split("|");
    el.setAttribute(keys[0], i18next.t(keys[1])); 
  });
  
  callback(i18next.resolvedLanguage);
  
}

/**
 * For language switcher
 */
function bindLocaleSwitcher(initialValue) {
	/*
  const switchers = document.querySelectorAll(
    "[data-i18n-switcher]",
  );
  
  switchers.forEach((switcher) => {
	  switcher.value = initialValue;
	  switcher.onchange = (e) => {
	    i18next
	      .changeLanguage(e.target.value)
	      .then(translatePageElements);
  		//make sure all the switchers are showing the same val
  		$(switchers).val(e.target.value);
	  };
  });*/
  $('[data-i18n-switcher]').on('click', function() { 
	  var $this = $(this);
	  i18next
	      .changeLanguage($this.data('i18n-switcher'))
	      .then(translatePageElements);
  });
  
}


