/**
 * Main javascript file for CLIA
 * This file uses revealing module pattern
 */

import $ from "jquery";
import gsap from "gsap";
import {initTranslations} from './translations';

import { tns } from "tiny-slider/src/tiny-slider"

import { Fancybox } from "@fancyapps/ui";
import "@lottiefiles/lottie-player";

//add .playing as a custom property to videos
//ref: https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function() {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
})

var clia = function($) {

	var $body = $('body'),
		$popupCloseButton = $('#popup-close'),
		videoLoadedCount = 0,
		previousSlide = 1,
		tl1,tl2,tl3,tl4,tl5;

    var init = async function() {

	    initialisePreloader();
	    await initTranslations(onLanguageChange);
	    initialiseGlobal();
	    initialiseFancybox();
	    initialiseSliders();
	    initialiseAnimations();
    }

    var onLanguageChange = function(language) {

		//load in content for specified language
		var $container = $('.section4 .stories');
		if ($container.length) {
			$container.empty();
			$.ajax({
			  dataType: "html",
			  async: false,
			  url: "/content/news/index-"+language+".htm",
			  success: function(html) {
				  $container.html(html);

				  //stop clicks on stories section triggering swipe
				  $('.section4 .stories a').on("touchstart touchend touchmove dragstart dragmove dragend", function(event) {
		    		  event.stopPropagation();
				  });
			  }
		  	});
		}
	    
	    $body.removeClass('en fr de no es it pt').addClass(language);
	    
	    //hide the language change popup
		Fancybox.close(false);
		
		//if we're looking at an individual story we need to close that too
		if ($('.fancybox__container.news').length) {
			Fancybox.close(false);
		}
		
		//re-init fancybox so the prev / next buttons are in the right language
		initialiseFancybox();
		
		if (previousSlide==5 || $('.fancybox__container.stories').length) {
			animateSection4();
		}
		
		//switch lottie hotspot animations		
		$('.hotspot lottie-player').each(function() {
			this.load("assets/animations/hotspot-"+language+".json");
		});
		
	}

	var videoLoaded = function() {
		videoLoadedCount++;
		//console.log(videoLoadedCount+' videos loaded');
		if (videoLoadedCount==3) {
			$('#preloader').fadeOut();
		}
	}

    /*
     * preloader (might need to move this since this script is loaded using defer)
     */
    var initialisePreloader = function() {

    	//alert('ios = '+iOSDevice);
		//alert('canplay');
		$('.video > video').each(function(index) {
	    	this.addEventListener('canplaythrough', (event) => {
        		//console.log('main.js caught canplaythrough');
        		//videoLoaded();
    		});

    		this.addEventListener('canplay', (event) => {
    		  //console.log('main.js caught canplay');
    		  //alert('caught canplay')
    		  videoLoaded();
    		});

		    // If the video is in the cache of the browser,
		    // the 'canplaythrough' event might have been triggered
		    // before we registered the event handler.
			if (this.readyState > 3) {
	      		//console.log('main.js video '+(index+1)+' is ready');
        		videoLoaded();
	    	}
		});

		//setTimeout(function() {
		//	$('#preloader').fadeOut();
		//}, 3000);
	}

    /*
     * global
     */
    var initialiseGlobal = function() {

    	//fix for viewport height units
    	//ref: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    	var vh = window.innerHeight;
    	// Then we set the value in the --vh custom property to the root of the document
    	document.documentElement.style.setProperty('--vh', vh+'px');

    	/*
    	$window.on('resize', function() {
    		var vh = window.innerHeight;
    		document.documentElement.style.setProperty('--vh', vh+'px');
    		//console.log('caught window resize: ',vh);
    	});*/

        //side navigation
        /*
        $('#navigation ul a').on('click', function(e) {
        	e.preventDefault();
        	var $this = $(this),
        		$target = $($this.attr('href'));
        	if ($target.length) {
				animateScroll($target);
        	}
        });*/

		$('.popup-trigger').on('click', function(e) {
			e.stopPropagation();
			var $this = $(this),
				$target = $($this.data('target'));

			$('.popup.active, .hotspot.active').not($this).not($target).removeClass('active');
			$target.addClass('active');
			$this.addClass('active');
			$('.popup.more').removeClass('more');
			
			//$this.closest('.hotspots').addClass('hotspot-active');
			$body.addClass('hotspot-active');
		});

		//these popup close buttons only appear on mobile
		/*
		$('.popup button.close').on('click', function() {
			$('.popup.active, .hotspot.active').removeClass('active');
			$('.popup.more').removeClass('more');
		});*/

		//read more functionality on hotspots
		$('.popup button.more').on('click', function() {
			$(this).closest('.popup').addClass('more');
		});

		//any sections that contain a 'scroll tip'
		/*
    	$('section .scroll-tip').on('click', function() {
			animateScroll($(this).parent().next('section'));
        });*/

        //animation defaults
        gsap.defaults({
		  ease: "power3.inOut",
		  duration: 2
		});

		//open / close hotspots
		/*
		$('section button.hotspots-trigger').on('click', function() {
			var $section = $(this).closest('section');
			$section.find('.content').fadeOut();
			$section.find('.hotspots').fadeIn();
			$section.addClass('hotspots');
			$body.addClass('mobile-dark');
		});

		$('section .hotspots button.back').on('click', function() {
			var $section = $(this).closest('section');
			$section.find('.hotspots').fadeOut();
			$section.find('.content').fadeIn();
			$section.removeClass('hotspots');
			$body.removeClass('mobile-dark');
		});*/

		//close any popups when we click on the background
		$('section .hotspots').on('click', function(e) {
			//console.log($(e.target).attr('class'));
			//if ($(e.target).hasClass('hotspots')) {
			//	$(this).find('.popup, .hotspot').removeClass('active');
     		//}
     		$(this).find('.popup.active, .hotspot.active').removeClass('active');
     		$body.removeClass('hotspot-active');
		});

		//stop clicks on stories section triggering swipe
		$('.section4 .stories a').on("touchstart touchend touchmove dragstart dragmove dragend", function(event) {
    		event.stopPropagation();
		});
    }
    
    
    var initialiseAnimations = function() {
		
		//intro animation
		tl1 = gsap.timeline();
		///tl1.paused(true);
		tl1.from(".intro .content", {y: 150, autoAlpha: 0 });
		
		//animation for section 1
		tl2 = gsap.timeline(); 	
		//tl2.paused(true)/*.timeScale(10)*/;			
        tl2
		    .to('.section1', {backgroundImage:'radial-gradient(circle at center, rgba(255,255,255, .6) 0%, rgba(255,255,255, .4) 25%, transparent 100%)'})
	    	.from('.section1 h2', {y: 150, autoAlpha: 0 }, "<")//"<" The start of previous animation**
	    	.to('.section1 h2', {y: 150, autoAlpha: 0, delay: 2 })
	    	.from('.section1 .content', {y: 150, autoAlpha: 0, delay: -1 })
	    	//.fromTo('.section1 .content', {y: 150, opacity: 0 }, {y: 0, opacity: 1, delay: -1 })
	    	.from('.section1 .hotspot', {zoom: 5, autoAlpha: 0, duration: 0.5, stagger: 0.25 });
		tl2.pause(0.01);//using 0.01 instead of 0 fixes the initial opacity on .section1 .content

		//the paused timelines are setting visibuility: inherit therefore making the content blocks visible
		//ref: https://gsap.com/community/forums/topic/7917-initial-visiblity-after-restarting-timeline/

		//section 2		
		tl3 = gsap.timeline(); 	
		//tl3.paused(true)/*.timeScale(10)*/;		
        tl3
		    .to('.section2', {backgroundImage:'radial-gradient(circle at center, rgba(255,255,255, .6) 0%, rgba(255,255,255, .4) 25%, transparent 100%)'})
	    	.from('.section2 h2', {y: 150, autoAlpha: 0 }, "<")//"<" The start of previous animation**
	    	.to('.section2 h2', {y: 150, autoAlpha: 0, delay: 2 })
	    	.from('.section2 .content', {y: 150, autoAlpha: 0, delay: -1 })
	    	.from('.section2 .hotspot', {zoom: 5, autoAlpha: 0, duration: 0.5, stagger: 0.25 });
		tl3.pause(0.01);//using 0.01 instead of 0 fixes the initial opacity on .section1 .content
	    	
	    //section 3
    	tl4 = gsap.timeline(); 	
		//tl4.paused(true);
		tl4
		    .to('.section3', {backgroundImage:'radial-gradient(circle at center, rgba(255,255,255, .6) 0%, rgba(255,255,255, .4) 25%, transparent 100%)'})
	    	.from(".section3 h2", {y: 150, autoAlpha: 0 }, "<")//"<" The start of previous animation**
	    	.to('.section3 h2', {y: 150, autoAlpha: 0, delay: 2 })
	    	.from('.section3 .content', {y: 150, autoAlpha: 0, delay: -1 })
	    	.from('.section3 .hotspot', {zoom: 5, autoAlpha: 0, duration: 0.5, stagger: 0.25 });
		tl4.pause(0.01);//using 0.01 instead of 0 fixes the initial opacity on .section1 .content
		/*
    	tl5 = gsap.timeline(); 	
		tl5
	    	.from('.section4 .stories a', {zoom: 5, autoAlpha: 0, duration: 0.5, stagger: 0.25 });
		tl5.pause(0.01);*/
    	
		//play first animation
		//onSlideChanged(1); no longer needed now first animation is not paused
		
	}
    
    var animateIntro = function() {
		tl1.play();
		
	    //play the video on this slide	    
	    const video = $('.intro.tns-slide-active video')[0];
	    video.play();
	}

    
    var animateSection1 = function() {
			
		tl2.play();
		
	    //play the video on this slide	    
	    const video = $('.section1.tns-slide-active .videoa')[0];
	    
	    //add listener for the video ending
	    video.addEventListener("ended", () => {
  			$('.section1.tns-slide-active .videoa').addClass('hidden');
  			if ($('.section1.tns-slide-active .videob').length) {
	  			$('.section1.tns-slide-active .videob').removeClass('hidden');
	  			$('.section1.tns-slide-active .videob')[0].play();
  			}
		});
		
    	video.play();
	}

    //reset a section so the animations can start again
    var resetSection = function(displayIndex) {
		//console.log('in resetSection, displayIndex is ', displayIndex)
		if (displayIndex) {
			var $section = $('.section.displayindex'+displayIndex);
			if ($section.length) {
				//$section.find('.hotspots').hide();
				//$('.section1').find('.hotspots').hide();
				$section.find('.popup, .hotspot, .sidebar, .subsidebar').removeClass('active');
				$section.find('.content').show();
				$section.removeClass('hotspots');
				$body.removeClass('hotspot-active');
				//reset the animation
				if (displayIndex==1) {
					tl1.pause(0.01);
				} else if (displayIndex==2) {
					tl2.pause(0.01);
				} else if (displayIndex==3) {
					tl3.pause(0.01);
				} else if (displayIndex==4) {
					tl4.pause(0.01);
				} else if (displayIndex==5) {
					tl5.revert();
				}
				
				//also want to stop videos playing
				$section.find('video').each(function(index) {
					var video = $(this).get(0);
					
				    if (video.playing) {
						//console.log('pausing video'+index);
						video.pause();
					}
					if (video.currentTime !== 0) {
						//console.log('setting currentTime of video '+index+' to 0');
    					video.currentTime = 0;
  					}
				});
				$section.find('.videoa').removeClass('hidden');
				$section.find('.videob').addClass('hidden');
			}
		}
	}
    
    var animateSection2 = function() {
		
		tl3.play();
	    	
	    //play the video on this slide	    
	    const video = $('.section2.tns-slide-active video')[0];
	    
	    //add listener for the video ending
	    video.addEventListener("ended", () => {
  			$('.section2.tns-slide-active .videoa').addClass('hidden');
  			if ($('.section2.tns-slide-active .videob').length) {
	  			$('.section2.tns-slide-active .videob').removeClass('hidden');
	  			$('.section2.tns-slide-active .videob')[0].play();
  			}
		});
		
    	video.play();
	}
    
    var animateSection3 = function() {
		
		tl4.play();
	    	
	    //play the video on this slide	    
	    const video = $('.section3.tns-slide-active video')[0];
	    
	    //add listener for the video ending
	    video.addEventListener("ended", () => {
  			$('.section3.tns-slide-active .videoa').addClass('hidden');
  			if ($('.section3.tns-slide-active .videob').length) {
	  			$('.section3.tns-slide-active .videob').removeClass('hidden');
	  			$('.section3.tns-slide-active .videob')[0].play();
  			}
		});
		
    	video.play();
	}
    
    var animateSection4 = function() {		
		
		tl5 = gsap.timeline(); 	
		tl5
			.from('.fancybox__viewport .section4 .stories a:nth-child(-n+14), .tns-slide-active.section4 .stories a:nth-child(-n+14)', {scale: 1.5, autoAlpha: 0, duration: 0.25, /*stagger: 0.125*/
		      stagger: {
		        each: 0.1,
		        from: "random"
		      }
		    })
			.set('.fancybox__viewport .section4 .stories a:nth-child(n+15), .tns-slide-active.section4 .stories a:nth-child(n+15)', {scale: 1, autoAlpha: 1 });
		
	    //play the video on this slide	    
	    const video = $('.fancybox__viewport .section4 video, .section4.tns-slide-active video')[0];
	    if (video) {
	    	video.play();
	    }
	}
    
    var onSlideChanged = function(displayIndex) {
		
	    if (displayIndex!=previousSlide) {
			
			//reset previous slide
			resetSection(previousSlide);
			
			if (displayIndex!=1 && displayIndex!=5) {
				$body.removeClass('hide-footer');
			}
		  
		    // direct access to info object
		    //console.log(info);
		    if (displayIndex==1) {
			    animateIntro();
		    } else if (displayIndex==2) {
			    animateSection1();
		    } else if (displayIndex==3) {
			    animateSection2();
		    } else if (displayIndex==4) {
			    animateSection3();
		    } else if (displayIndex==5) {
				console.log('calling animateSection4')
			    animateSection4();
		    }
		  
		    previousSlide = displayIndex;
	    }
	}

    //init sliders
	var initialiseSliders = function() {

		//this is our main vertical slider
		var mainSlider = tns({
		    container: '#clia-spa',
		    items: 1,
		    slideBy: 'page',
		    autoplay: false,
		    nav: false,
		    touch: true,
		    preventScrollOnTouch: 'auto',
			axis: 'vertical',
			prevButton: '#nav-prev',
			nextButton: '#nav-next',
		});
		
		mainSlider.events.on('transitionStart', function(info) {
			
			//console.log('transitionStart', info.displayIndex);	
			if (info.displayIndex==1 || info.displayIndex==5) {//moving TO slide 1
				$body.addClass('hide-footer');
			}
			$('#change-language').toggle(info.displayIndex!=1);
		});
		
		mainSlider.events.on('transitionEnd', function(info) {
			//console.log('calling onSlideChanged');
			onSlideChanged(info.displayIndex);			
		});
		
		//slide 1 - intro
		$('#clia-spa').on('click', '.intro .button.start', function() {
			mainSlider.goTo('next');
		});
		//mainSlider.goTo(4);//TEMP
		
		//prevent clicks on the hotspots triggering a swipe
		$('.hotspots').on("touchstart touchend touchmove dragstart dragmove dragend", function(event) {
			if ($body.hasClass('hotspot-active')) {
	    		event.stopPropagation();
	    	}
		});
		
		$('.hotspot').on("touchstart touchend touchmove dragstart dragmove dragend", function(event) {
    		event.stopPropagation();
		});

		//close button on stories page		
		$('#clia-spa').on('click', '#stories-close', function() {
			mainSlider.goTo('next');
		});
    }

    /**
     * Fancybox popups
     */
    var initialiseFancybox = function() {

		$popupCloseButton.off('click').on('click', function() {
			Fancybox.close(false);
			//only hide the close button if all instances are now hidden
			if ($('.fancybox__container').length==1) {
	        	$popupCloseButton.hide();
	        }
		});

		Fancybox.unbind('[data-fancybox="news"]'); 

		//for popups
		Fancybox.bind('[data-fancybox="news"]', {
	        preload: 0,
	        infinite: false,
	        hideScrollbar: false,
	        mainClass: 'news',
		    Carousel: {
			    Navigation: {
			        prevTpl: '<svg class="icon prev"><use xlink:href="#ic_arrow_left"></use></svg>'+$('#nav-prev .label').text(),
			        nextTpl: $('#nav-next .label').text()+'<svg class="icon prev"><use xlink:href="#ic_arrow_right"></use></svg>',
			    },
		    },
		    Toolbar: {
			    display: [],
			},
			Hash: false,
			on: {
			    reveal: () => {
			        //console.log('open');
			        $popupCloseButton.show();
			    },
			    close: () => {
			        //console.log('close');
			        $popupCloseButton.hide();
			    },
		    },
		});
		
		//stories from around our world button		
		$('#stories-trigger').off('click').on('click', function() {
			Fancybox.show([{ 
				src: "#stories", 
				type: "clone"
			}], {
				mainClass: 'stories',
				on: {
				    reveal: () => {
				        console.log('open');
				        $popupCloseButton.show();
				        animateSection4();
				        //onSlideChanged(5);	
				    },
				    close: () => {
				        console.log('close');
				        $popupCloseButton.hide();
				    },
			    }
			}); 
		});
		
		//button to show language selector
		$('.button.language').off('click').on('click', function() {
			Fancybox.show([{ 
				src: "#language-selector", 
				type: "inline"
			}], {
				mainClass: 'language',
			}); 
		});

    }

    return{init:init}//return items that are available outside

}($);

//document ready
$(function() {
	clia.init();
});
